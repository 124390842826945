/*

    1. BASE
    reset
    base

    2. Element
    Header
    Footer
    Button
    Form
    Icon
    Alerts
    Graphics
    GridIcons
    BannerDonate
    Stepper
    Causal Card
    Modal
    Card
    Tooltip
    Dropzone
    Violentometro
    Status Bar
    Chatbox
    Badges
    Rating
    Accordion
    Calendar
    DataTable
    TagManager
    NotificationMessage
    Notes
    ProcessCard
    UserCard
    Paginator
    Quality
    Chart
    Donatios Tabs

    3. Layout
    Auth
    Donate
    Dashboard
    User Detail
    Talleres

    4. responsive
    Large
    Medium
    Small

*/
/* ------------------------------------------------------------
     VARIABLES
------------------------------------------------------------ */
/* ------------------------------------------------------------
     MIXINS
------------------------------------------------------------ */
/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
  -webkit-text-size-adjust: none;
  /* For iphone Landscape */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* For font looks antialiased */
  -moz-osx-font-smoothing: grayscale;
  /* Moz antialiased */
  text-rendering: optimizeLegibility;
  /* optimezy fonts */
  font-size: 16px;
  color: #484848;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 25px;
  height: 100%;
}

/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
blockquote,
h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.5px;
  font-weight: bold;
  font-family: "Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1,
.h1 {
  font-size: 35px;
  line-height: 40px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 35px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 25px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 25px;
}

/* outline */
* {
  -ms-touch-action: manipulation;
  -webkit-touch-action: manipulation;
  touch-action: manipulation;
}
*:focus {
  outline: none !important;
}

/* Placeholder */
::-webkit-input-placeholder {
  color: #CDCDCD !important;
}

::-moz-placeholder {
  color: #CDCDCD !important;
} /* firefox 19+ */
:-ms-input-placeholder {
  color: #CDCDCD !important;
} /* ie */
:-moz-placeholder {
  color: #CDCDCD !important;
}

/* Selection */
::-moz-selection {
  color: #fff;
  background: #845DB6;
  opacity: 1 !important;
}
::selection {
  color: #fff;
  background: #845DB6;
  opacity: 1 !important;
}

::-moz-selection {
  color: #fff;
  background: #845DB6;
  opacity: 1 !important;
}

/* mx auto */
.mx-auto {
  margin: 0 auto;
}

.col-reset {
  padding: 0;
}

/* Hidden */
.hidden {
  display: none !important;
}

/* Address */
address {
  font-style: initial;
}

/* padding */
.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

/* Margin */
.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mr16 {
  margin-right: 16px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.mh-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* text color */
.text-base,
.text-base * {
  color: #484848;
}

.text-gray420,
.text-gray420 * {
  color: #B1B1B1;
}

.text-gray450,
.text-gray450 * {
  color: #CDCDCD;
}

.text-gray600,
.text-gray600 * {
  color: #8A8A8A;
}

.text-gray700,
.text-gray700 * {
  color: #707070;
}

.text-gray800,
.text-gray800 * {
  color: #545454;
}

.text-green,
.text-green * {
  color: #529770;
}

.text-green300,
.text-green300 * {
  color: #449A82;
}

.text-green500,
.text-green500 * {
  color: #1F7164;
}

.text-green550,
.text-green550 * {
  color: #46BC7C;
}

.text-green900,
.text-green900 * {
  color: #22594F;
}

.text-purple300,
.text-purple300 * {
  color: #C8A4E9 !important;
}

.text-purple500,
.text-purple500 * {
  color: #845DB6 !important;
}

.text-purple800,
.text-purple800 * {
  color: #54326F;
}

.text-pink500,
.text-pink500 * {
  color: #D295BF;
}

.text-red,
.text-red * {
  color: #AC0B1F;
}

/* text size */
.text-64,
.text-64 * {
  font-size: 64px;
  line-height: 70px;
}

.text-52,
.text-52 * {
  font-size: 52px;
  line-height: 60px;
}

.text-40,
.text-40 * {
  font-size: 40px;
  line-height: 45px;
}

.text-32,
.text-32 * {
  font-size: 32px;
  line-height: 40px;
}

.text-28,
.text-28 * {
  font-size: 28px;
  line-height: 30px;
}

.text-24,
.text-24 * {
  font-size: 24px;
  line-height: 30px;
}

.text-20,
.text-20 * {
  font-size: 20px !important;
  line-height: 30px;
}

.text-18:not(.material-icons-outlined),
.text-18 *:not(.material-icons-outlined) {
  font-size: 18px !important;
  line-height: 25px !important;
}

.text-16:not(.material-icons-outlined),
.text-16 *:not(.material-icons-outlined) {
  font-size: 16px !important;
  line-height: 25px !important;
}

.text-14,
.text-14 * {
  font-size: 14px;
  line-height: 25px;
}

/* icon */
.text-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.text-icon i {
  margin-right: 8px;
}

.text-hide {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  top: -2px;
  margin-left: 4px;
}
.text-hide * {
  margin-right: 4px;
  font-size: 8px;
  line-height: 20px;
}

/* text font */
.text-regular,
.text-regular * {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-titular,
.text-titular * {
  font-family: "Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-400,
.text-400 * {
  font-weight: 400;
}

.text-500,
.text-500 * {
  font-weight: 500;
}

.text-700,
.text-700 * {
  font-weight: 700;
}

.text-underline,
.text-underline * {
  text-decoration: underline;
}

.text-flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.text-right {
  text-align: right;
}

/* input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

/* hr pink */
.hr-pink {
  border-top-color: #D295BF;
}

/* ------------------------------------------------------------
     BASE
------------------------------------------------------------ */
#root,
.app-main {
  width: 100%;
  height: 100%;
}

/* module */
.module {
  padding-top: 80px;
  padding-bottom: 80px;
}

.module-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}

.module-top {
  padding-top: 80px;
  padding-bottom: 0;
}

.module40 {
  padding: 40px 0;
}

.module-bottom40 {
  padding-top: 0;
  padding-bottom: 40px;
}

.module-top40 {
  padding-top: 40px;
  padding-bottom: 0;
}

/* center list */
.center-list {
  list-style: none;
  padding: 24px 0;
  margin: 0;
}
.center-list li {
  margin-bottom: 32px;
}
.center-list li:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------
     HEADER
------------------------------------------------------------ */
header {
  padding: 25px 0;
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 99;
}

.header__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo {
  width: 150px;
  margin: 0;
  position: relative;
  z-index: 11;
}
.header__logo figure {
  margin: 0;
}
.header__logo img {
  width: 100%;
}

/* main nav */
.main-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav ul li {
  margin-left: 24px;
}
.main-nav ul li a {
  color: #484848;
  position: relative;
  text-decoration: none;
  font-weight: 400;
}
.main-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background: transparent;
}
.main-nav ul li a.active {
  color: #845DB6;
}
.main-nav ul li a.active:after {
  background: #845DB6;
}
.main-nav ul li a:hover {
  color: #845DB6;
}
.main-nav ul li a.btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
.main-nav ul li a.btn:after {
  display: none;
}

/* open nav */
.open-nav {
  width: 50px;
  height: 25px;
  position: relative;
  -webkit-transform: scale(0.7) rotate(0deg);
          transform: scale(0.7) rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: none;
  z-index: 11;
}
.open-nav.active span {
  background: #fff;
}
.open-nav.active span:nth-child(1), .open-nav.active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.open-nav.active span:nth-child(2), .open-nav.active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.open-nav.active span:nth-child(1) {
  left: 5px;
  top: 7px;
}
.open-nav.active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}
.open-nav.active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.open-nav.active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.open-nav.active span:nth-child(5) {
  left: 5px;
  top: 22px;
}
.open-nav.active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 22px;
}
.open-nav span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #845DB6;
  opacity: 1;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.open-nav span:nth-child(even) {
  left: 50%;
}
.open-nav span:nth-child(odd) {
  left: 0px;
}
.open-nav span:nth-child(1), .open-nav span:nth-child(2) {
  top: 0px;
}
.open-nav span:nth-child(3), .open-nav span:nth-child(4) {
  top: 11px;
}
.open-nav span:nth-child(5), .open-nav span:nth-child(6) {
  top: 22px;
}

/* user control */
.user-control {
  position: relative;
}
.user-control a {
  text-decoration: none;
}

.user-options {
  position: absolute;
  right: 0;
  top: 50px;
  width: 360px;
  background: #F8F2FF;
  padding: 20px;
  border: 2px solid #845DB6;
  border-radius: 12px 0 12px 12px;
  display: none;
  z-index: 10;
}

.user-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #B1B1B1;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.user-options__top article {
  padding-left: 24px;
}
.user-options__top article h3 {
  margin-bottom: 0;
}

.user-options__bottom ul {
  list-style: none;
  padding: 0 22px;
  margin: 0;
}
.user-options__bottom ul li {
  width: 100%;
  margin-bottom: 29px;
}
.user-options__bottom ul li:last-child {
  margin-bottom: 0;
}
.user-options__bottom ul li i {
  margin-right: 43px;
}
.user-options__bottom ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* notifications */
.header__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__options.header--admin .form--search {
  margin: 0 16px;
  width: 300px;
}
.header__options .header-alert {
  margin-right: 24px;
}

.icon-header {
  margin-right: 16px;
}

/* mobile options */
.main-nav__mobile {
  display: none;
}

.header-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.header-options__top > * {
  margin: 0 16px;
}
.header-options__top a {
  width: auto !important;
}

/* header search */
.header-search-module {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 840px;
  padding-right: 32px;
}
.header-search-module figure {
  margin-right: 16px;
}
.header-search-module .form--search {
  width: 100%;
  max-width: 791px;
}

/* header admin */
.header-admin {
  background: #F6F6F6;
}
.header-admin .open-nav,
.header-admin .main-nav {
  display: none;
}

.header-admin__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
}
.header-admin__top .icon-header {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1400px) {
  .header__options {
    display: none;
  }
  .open-nav {
    display: block;
  }
  .main-nav {
    opacity: 0;
    pointer-events: none;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .main-nav ul {
    background: #845DB6;
    height: auto;
    display: block;
    padding: 100px 15px 30px 15px;
  }
  .main-nav ul li {
    width: 100%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    padding: 0 15px;
  }
  .main-nav ul li:last-child {
    margin-bottom: 0;
  }
  .main-nav ul li a {
    color: #fff;
    width: 100%;
    display: block;
  }
  .main-nav ul li a.active {
    color: #F1BCEF;
  }
  .main-nav ul li a.active:after {
    background: #F1BCEF;
  }
  .main-nav ul li a:hover {
    color: #F1BCEF;
  }
  .main-nav ul li a.btn {
    background: #fff;
    color: #845DB6;
    line-height: 40px;
  }
  .main-nav.active {
    opacity: 1;
    pointer-events: initial;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .header__logo {
    width: 200px;
  }
}
@media (max-width: 1300px) {
  .main-nav ul li {
    margin-left: 34px;
  }
}
@media (max-width: 1200px) {
  .main-nav ul li {
    margin-left: 34px;
  }
  .header__options {
    position: relative;
  }
  .header__options .header-alert {
    position: absolute;
    top: 60px;
    right: 0;
  }
  .header__options .header-alert p {
    font-size: 14px;
  }
  .main-nav__mobile {
    display: block;
  }
}
@media (min-width: 993px) {
  .user-control:hover .user-options {
    display: block;
  }
  .user-control-button {
    pointer-events: none;
  }
}
@media (max-width: 992px) {
  .header-admin .open-nav,
  .header-admin .main-nav {
    display: block;
  }
  .header-admin__top .icon-header {
    margin-right: 16px;
  }
  .open-nav {
    width: 65px;
  }
}
@media (max-width: 768px) {
  .main-nav ul li {
    max-width: 540px;
    margin-left: 0;
  }
  .header-admin__top {
    padding: 16px;
  }
}
@media (max-width: 680px) {
  .main-nav ul {
    padding-top: 120px;
  }
  .main-nav ul li {
    padding: 0 30px;
  }
}
/* ------------------------------------------------------------
     FOOTER
------------------------------------------------------------ */
footer {
  background: #E9D8FE;
  padding: 32px 0;
  width: 100%;
}
footer .container p,
footer .container a,
footer .container h1,
footer .container h2,
footer .container h3,
footer .container h4,
footer .container h5,
footer .container h6 {
  color: #54326F;
}
footer .container a {
  font-weight: 400;
  text-decoration: none;
}

.footer__logo {
  width: 240px;
  display: block;
  margin-bottom: 16px;
}
.footer__logo figure {
  margin: 0;
  width: 100%;
}
.footer__logo figure img {
  width: 100%;
}

.footer__row {
  width: 100%;
  margin-bottom: 16px;
}

/* social */
.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.social li {
  margin-right: 24px;
}

/* contact */
.contact {
  padding: 0;
  margin: 0;
  list-style: none;
}
.contact li {
  width: 100%;
  margin-bottom: 8px;
}
.contact li:last-child {
  margin-bottom: 0;
}
.contact li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact li a .icon {
  margin-right: 8px;
}

/* quick links */
.quicklinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.quicklinks li {
  width: 100%;
  margin-bottom: 16px;
}
.quicklinks li:last-child {
  margin-bottom: 0;
}
.quicklinks li a {
  font-weight: bold;
}

.footer__col .quicklinks {
  display: none;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .footer__col {
    margin-bottom: 32px;
  }
  .footer__col .quicklinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__col .quicklinks li {
    width: 100%;
    margin-bottom: 24px;
  }
  .footer__col .quicklinks li:last-child {
    margin-bottom: 0;
  }
  .footer__quicklinks {
    display: none;
  }
}
/* ------------------------------------------------------------
     BUTTON
------------------------------------------------------------ */
a,
button {
  color: #845DB6;
  font-weight: bold;
  border: 0;
  background: transparent;
  cursor: pointer;
}
a.btn--simple,
button.btn--simple {
  font-size: 20px;
  text-decoration: none;
}
a.btn--simple:hover,
button.btn--simple:hover {
  color: #54326F;
}
a.btn--simple--small,
button.btn--simple--small {
  font-size: 16px;
}
a.btn--simple--small.btn--icon .icon,
button.btn--simple--small.btn--icon .icon {
  margin-right: 8px;
}
a.btn--danger,
button.btn--danger {
  color: #AC0B1F;
  font-size: 20px;
  text-decoration: none;
}
a.btn--back,
button.btn--back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
}
a.btn--back .icon,
button.btn--back .icon {
  margin-right: 8px;
}

.btn {
  min-height: 61px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
}
.btn.btn--type1 {
  color: #fff;
  background: #845DB6;
}
.btn.btn--type1:hover {
  background: #54326F;
}
.btn.btn--type2 {
  color: #845DB6;
  background: #E9D8FE;
  border: 2px solid #845DB6;
}
.btn.btn--type2:hover {
  background: #E9D8FE;
  border-color: #E9D8FE;
  color: #54326F;
}
.btn.btn--type3 {
  color: #845DB6;
  background: #E9D8FE;
  border: 2px solid #845DB6;
}
.btn.btn--type3:hover {
  background: #E9D8FE;
  border-color: #E9D8FE;
  color: #54326F;
}
.btn.btn--type4 {
  border: 2px solid #845DB6;
  background: #fff;
  color: #845DB6;
}
.btn.btn--type4:hover {
  background: #E9D8FE;
  border-color: #E9D8FE;
  color: #54326F;
}
.btn.btn--type5 {
  background: #E9D8FE;
  color: #845DB6;
}
.btn.btn--type5:hover {
  background: #fff;
}
.btn.btn--type5 .icon {
  margin-right: 8px;
}
.btn.btn--delete {
  color: #fff;
  background: #9D1D2A;
}
.btn.btn--delete:hover {
  background: #AC0B1F;
}
.btn.btn--chat {
  color: #fff;
  background: #845DB6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 24px;
  height: 57px;
}
.btn.btn--chat:hover {
  background: #54326F;
}
.btn.btn--chat i {
  margin-left: 16px;
}
.btn[disabled] {
  background: #CDCDCD;
  color: #8A8A8A;
  border-color: #CDCDCD;
}
.btn.btn--disabled {
  pointer-events: none;
}
.btn.btn--270 {
  max-width: 270px;
}
.btn.btn--100 {
  max-width: 100%;
  width: 100%;
}
.btn.btn--290 {
  width: 290px;
}
.btn.btn--370 {
  width: 370px;
}
.btn.btn--code {
  border: 1px solid #C8A4E9;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 138px;
  width: 322px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn.btn--code:hover, .btn.btn--code.active {
  border-color: #54326F;
  background: #F4E0FB;
}
.btn.btn--code:hover i,
.btn.btn--code:hover p, .btn.btn--code.active i,
.btn.btn--code.active p {
  color: #54326F;
}
.btn.btn--code i {
  font-size: 30px;
}
.btn.btn--code p {
  width: 80%;
  text-align: center;
  margin: 0;
}
.btn.btn--p20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.btn.btn--icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn.btn--icon .icon {
  font-size: 32px;
}
.btn.btn--small {
  width: 129px !important;
  border-radius: 12px;
  font-size: 14px;
  height: 32px;
  padding: 0 32px;
  min-height: initial;
}
.btn.btn--small-100 {
  border-radius: 12px;
  font-size: 14px;
  height: 32px;
  padding: 0 32px;
  min-height: initial;
}
.btn.btn--logout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #54326F;
  border: 0;
}
.btn.btn--logout:hover {
  color: #484848;
}
.btn.btn--logout .icon {
  margin-right: 8px;
}
.btn.btn--list {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 0;
}
.btn.btn--list:hover {
  background: #FBF8FF;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(191, 156, 236, 0.87), 0px 0px 5px 2px #A57FD6;
          box-shadow: 0px 0px 10px 0px rgba(191, 156, 236, 0.87), 0px 0px 5px 2px #A57FD6;
}
.btn.btn--list div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn.btn--list div i {
  margin-right: 8px;
}
.btn.btn--taller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}
.btn.btn--taller figure {
  margin-right: 24px;
}
.btn.btn--taller p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  padding: 0;
  margin: 0;
}
.btn.btn--taller p span {
  display: block;
  width: 100%;
}
.btn.btn--colectiva {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  padding: 8px 16px;
  background: #F6F6F6;
  font-size: 16px;
}
.btn.btn--colectiva p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 200px;
}
.btn.btn--colectiva p span {
  display: block;
  width: 100%;
}
.btn.btn--colectiva .data-table__number {
  font-weight: 400;
  color: #8A8A8A;
  font-size: 14px;
}
.btn.btn--with-number {
  background: #E9D8FE;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  height: 39px;
  min-height: initial;
}
.btn.btn--with-number i {
  margin-left: 20px;
}

.btn--list__label {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-size: 16px;
}

.btn--list__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
}

.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.button-container .btn {
  margin: 0 8px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .btn.btn--370 {
    width: 100%;
  }
  .button-container .btn {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .button-container .btn:last-child {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------
     FORM
------------------------------------------------------------ */
.form-block {
  width: 100%;
}
.form-block form {
  width: 100%;
}

.form-row {
  margin-bottom: 21px;
  width: 100%;
}
.form-row:last-child {
  margin-bottom: 0;
}
.form-row.form--last {
  text-align: center;
}
.form-row.form--last .btn {
  width: 100%;
}
.form-row.last--button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-row.last--button .btn {
  width: auto;
  margin: 0 8px;
}
.form-row.form--search {
  position: relative;
}
.form-row.form--search input {
  padding-left: 36px;
  border-width: 2px;
}
.form-row.form--search .icon {
  position: absolute;
  left: 10px;
  top: 18px;
}

small.required {
  color: #845DB6;
}

.form-label {
  font-size: 16px;
}
.form-label.label--icon {
  text-wrap: balance;
}
.form-label.label--icon .icon {
  color: #845DB6;
  font-size: 21px;
  line-height: 25px;
  position: relative;
  top: 4px;
  margin-left: 4px;
}
.form-label.error {
  color: #DB443A;
}
.form-label.label--title {
  font-family: "Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: 700;
}
.form-label.label--title.label--icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-label.label--title.label--icon span {
  font-size: 18px;
  margin-right: 8px;
}

.form-control,
.form-select {
  border-color: #D295BF;
  border-radius: 12px;
  height: 56px;
}
.form-control:disabled,
.form-select:disabled {
  background-color: #F6F3F0;
  /* firefox 19+ */
  /* ie */
}
.form-control:disabled::-webkit-input-placeholder,
.form-select:disabled::-webkit-input-placeholder {
  color: #484848 !important;
}
.form-control:disabled::-moz-placeholder,
.form-select:disabled::-moz-placeholder {
  color: #484848 !important;
}
.form-control:disabled:-ms-input-placeholder,
.form-select:disabled:-ms-input-placeholder {
  color: #484848 !important;
}
.form-control:disabled:-moz-placeholder,
.form-select:disabled:-moz-placeholder {
  color: #484848 !important;
}
.form-control:focus,
.form-select:focus {
  border-color: #D295BF;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
          box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
}
.form-control.form--green,
.form-select.form--green {
  border-color: #91D6C4;
}
.form-control.form--green:focus,
.form-select.form--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.form-control.error,
.form-select.error {
  border-color: #DB443A;
}
.form-control.form--small,
.form-select.form--small {
  background-color: #F6F6F6;
  background-size: 18px;
  border: 0;
  height: 40px;
  font-size: 13px;
  /* firefox 19+ */
  /* ie */
}
.form-control.form--small::-webkit-input-placeholder,
.form-select.form--small::-webkit-input-placeholder {
  color: #484848 !important;
}
.form-control.form--small::-moz-placeholder,
.form-select.form--small::-moz-placeholder {
  color: #484848 !important;
}
.form-control.form--small:-ms-input-placeholder,
.form-select.form--small:-ms-input-placeholder {
  color: #484848 !important;
}
.form-control.form--small:-moz-placeholder,
.form-select.form--small:-moz-placeholder {
  color: #484848 !important;
}
.form-control.form--medium,
.form-select.form--medium {
  height: 38px;
}
.form-control.form--medium.form-select,
.form-select.form--medium.form-select {
  background-size: 15px;
}
.form-control.form--center,
.form-select.form--center {
  text-align: center;
}
.form-control.form--filled,
.form-select.form--filled {
  background: #FCEEF2;
}

.form-control.form--amount {
  border: 0;
  background: transparent;
}

.form-input-hide {
  border: 1px solid #D295BF;
  border-radius: 12px;
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
}
.form-input-hide p {
  margin: 0;
}
.form-input-hide input {
  width: 140px;
  font-size: 24px;
  text-align: center;
}

textarea, textarea.form-control {
  height: 180px;
  resize: none;
}

.form-select {
  --bs-form-select-bg-img: url(/public/assets/images/icons/arrow-down.svg) ;
  background-size: 20px;
}

.form-password {
  position: relative;
}
.form-password .toggle-password {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 1;
}

/* password */
.toggle-password {
  border: 0;
  background: transparent;
  color: #D295BF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* password rules */
.password-rules {
  padding-top: 24px;
  color: #8A8A8A;
}
.password-rules ul li {
  color: #8A8A8A;
}

.password-strength {
  width: 100%;
  background: #CDCDCD;
  height: 15px;
  border-radius: 8px;
  position: relative;
}

.password-strength__bar {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
}

/* phone */
.react-international-phone-input-container {
  width: 100%;
}
.react-international-phone-input-container input[type=tel] {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #D295BF;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 56px;
  margin-left: 8px;
}
.react-international-phone-input-container input[type=tel]:focus {
  border-color: #D295BF;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
          box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
}
.react-international-phone-input-container button.react-international-phone-country-selector-button {
  border: var(--bs-border-width) solid #D295BF;
  border-radius: 12px;
  height: 56px;
}
.react-international-phone-input-container button.react-international-phone-country-selector-button .react-international-phone-country-selector-button__dropdown-arrow {
  background: url(/public/assets/images/icons/arrow-down.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: 0;
  margin-left: 8px;
}

/* verification code */
.vi__container {
  padding: 0;
  max-width: 400px;
  margin: 0;
}
.vi__container .vi__character {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #54326F;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid #D295BF;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 64px;
  line-height: 56px;
  margin-left: 0;
  font-size: 28px;
}
.vi__container .vi__character.character--selected {
  border-color: #D295BF;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
          box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
  outline: initial;
}
.vi__container .vi__character:not(.character--inactive) {
  border-color: #529770;
}

/* form check */
.form-check.check--small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.check--small .form-check-input {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  margin-top: 0;
}
.form-check.check--small label {
  font-weight: 500;
}
.form-check.accordeon--checker {
  position: relative;
  border-radius: 12px;
  padding: 10px 10px 12px 10px;
  margin-bottom: 24px;
}
.form-check.accordeon--checker:last-child {
  margin-bottom: 0;
}
.form-check.accordeon--checker input,
.form-check.accordeon--checker label {
  position: relative;
  z-index: 1;
}
.form-check.accordeon--checker input {
  margin-left: 0;
}
.form-check.accordeon--checker label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2px;
}
.form-check.form-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.form-switch input {
  width: 60px;
  height: 34px;
  margin-right: 16px;
  background-color: #B1B1B1;
  border: 0;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}
.form-check.form-switch input:after {
  display: none;
}
.form-check.form-switch input:checked {
  background-color: #845DB6;
}

.form-check-input[type=checkbox] {
  width: 28px;
  height: 28px;
  border: 1px solid #C8A4E9;
  margin-top: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-check-input[type=checkbox]:after {
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  opacity: 0;
  padding-top: 2px;
}
.form-check-input[type=checkbox]:checked {
  background-color: #fff;
  border-color: #E9D8FE;
  color: #845DB6;
}
.form-check-input[type=checkbox]:checked + .form-check-input__back {
  background: #C8A4E9;
}
.form-check-input[type=checkbox]:checked:after {
  opacity: 1;
}
.form-check-input[type=checkbox].checkbox--green {
  border-color: #91D6C4;
}
.form-check-input[type=checkbox].checkbox--green:checked {
  color: #2F6858;
}
.form-check-input[type=checkbox].checkbox--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}

.accordeon-number {
  padding-right: 16px;
}

.form-check-input__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F4E0FB;
  border-radius: 12px;
}

/* radiobutton */
.form-check-input {
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #C8A4E9;
  border-color: #C8A4E9;
}
.form-check-input:focus {
  border-color: #D295BF;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
          box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
}

/* form multi */
.form-check-multi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check-multi .form-check {
  margin-right: 32px;
}
.form-check-multi .form-check:last-child {
  margin-right: 0;
}

/* row check 2 */
.row-check-2 .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.row-check-2 .form-check label {
  padding-left: 16px;
}

.row-check-2__col {
  margin-bottom: 24px;
  padding-right: 0;
}

/* rating */
.rating-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 240px;
  width: 100%;
}
.rating-form svg {
  height: 2em;
}

/* form big row */
.form-big-row {
  border-bottom: 1px solid #FCC9FB;
  padding-bottom: 48px;
  margin-bottom: 48px;
}
.form-big-row:last-of-type, .form-big-row:last-child, .form-big-row.not-border {
  border-bottom: 0;
  padding-bottom: 0;
}
.form-big-row.big-row--last .btn {
  margin: 0 8px;
}

/* form search */
.form-search-complete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  z-index: 19;
}
.form-search-complete .autocomplete-search {
  width: 100%;
  margin-bottom: 0;
  padding-right: 16px;
}
.form-search-complete .autocomplete-search.search--100 {
  width: 100%;
}
.form-search-complete .autocomplete-search .icon {
  top: 20px;
}
.form-search-complete .btn {
  width: 30%;
}

.autocomplete-search .autocomplete-search__input .wrapper {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 12px;
  height: 56px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-color: #D295BF;
}
.autocomplete-search .autocomplete-search__input .wrapper:focus, .autocomplete-search .autocomplete-search__input .wrapper:focus-within {
  border-color: #D295BF;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
          box-shadow: 0 0 0 0.25rem rgba(210, 149, 191, 0.31);
}
.autocomplete-search .autocomplete-search__input .wrapper svg {
  margin-left: 0;
  width: 25px;
  height: 40px;
  fill: #8A8A8A;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer {
  border-radius: 18px;
  position: relative;
  top: 20px;
  background: #fff;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  padding: 0;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul {
  padding: 0;
  overflow: hidden;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul li[data-test=result].selected {
  background: #F4E0FB;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul li[data-test=result] svg {
  display: none;
}
.autocomplete-search .autocomplete-search__input .wrapper .line {
  display: none;
}

/* double button */
.double-btn-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.double-btn-action .btn {
  width: 45%;
}

.search-checks-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.search-checks-list li {
  width: 100%;
  margin-bottom: 16px;
}
.search-checks-list li:last-child {
  margin-bottom: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .form-search-complete .autocomplete-search {
    width: 50%;
  }
  .form-search-complete .btn {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .form-search-complete .autocomplete-search {
    width: 100%;
    margin-bottom: 32px;
    padding-right: 0;
  }
  .form-search-complete .form-row {
    width: 40%;
  }
  .form-search-complete .form-row .icon {
    top: 20px;
  }
  .form-search-complete .btn {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .form-row__col-small {
    margin-bottom: 21px;
  }
  .form-big-row {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .form-big-row.big-row--last .btn {
    margin: 0 8px 24px 8px;
    width: 100%;
  }
  .form-row.last--button {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .form-row.last--button .btn {
    width: 100%;
    margin: 0 auto 16px auto;
  }
  .form-search-complete .autocomplete-search {
    margin-bottom: 0;
  }
  .form-search-complete .form-row {
    width: 100%;
    padding-right: 0;
    padding-bottom: 16px;
  }
  .form-search-complete .form-row input {
    height: 56px;
  }
  .form-search-complete .btn {
    width: 100%;
    font-size: 16px;
  }
}
/* ------------------------------------------------------------
     ICON
------------------------------------------------------------ */
.icon {
  display: inline-block;
}

.icon--facebook {
  background: url(/public/assets/images/icons/facebook.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--instagram {
  background: url(/public/assets/images/icons/instagram.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--twitter {
  background: url(/public/assets/images/icons/twitter.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--phone {
  background: url(/public/assets/images/icons/phone.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--phone-call {
  background: url(/public/assets/images/icons/phone-call.svg) center center no-repeat;
  background-size: contain;
  width: 34px;
  height: 34px;
}

.icon--email {
  background: url(/public/assets/images/icons/email.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--add-document {
  background: url(/public/assets/images/icons/add-document.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--upload {
  background: url(/public/assets/images/icons/upload.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--upload-checked {
  background: url(/public/assets/images/icons/upload-checked.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--user-small {
  background: url(/public/assets/images/icons/user-pic.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--admin-small {
  background: url(/public/assets/images/icons/user-admin.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--superadmin {
  background: url(/public/assets/images/icons/user-pic-3.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--user-big {
  background: url(/public/assets/images/icons/user-big.svg) center center no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
}

.icon--search {
  background: url(/public/assets/images/icons/search.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--add-user {
  background: url(/public/assets/images/icons/add-user.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--search-bar {
  background: url(/public/assets/images/icons/search-bar.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--logout {
  background: url(/public/assets/images/icons/logout.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--notification-on {
  background: url(/public/assets/images/icons/notification-on.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--notification-on-white {
  background: url(/public/assets/images/icons/notification-on-white.svg) center center no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.icon--notification-off {
  background: url(/public/assets/images/icons/notification-off.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--money {
  background: url(/public/assets/images/icons/money.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--amex {
  background: url(/public/assets/images/icons/amex.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--mastercard {
  background: url(/public/assets/images/icons/mastercard.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--visa {
  background: url(/public/assets/images/icons/visa.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--paypal {
  background: url(/public/assets/images/icons/paypal.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--card {
  background: url(/public/assets/images/icons/card.svg) center center no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.icon--chat {
  background: url(/public/assets/images/icons/chat.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--message {
  background: url(/public/assets/images/icons/message.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--clock {
  background: url(/public/assets/images/icons/clock.svg) center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.icon--calendar-line {
  background: url(/public/assets/images/icons/calendar-line.svg) center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.icon--conversation {
  background: url(/public/assets/images/icons/conversation.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--love {
  background: url(/public/assets/images/icons/chat-love.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--back {
  background: url(/public/assets/images/icons/arrow-left.svg) center center no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
}

.icon--message-clip {
  background: url(/public/assets/images/icons/message-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--user-clip {
  background: url(/public/assets/images/icons/user-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--calendar-clip {
  background: url(/public/assets/images/icons/calendar-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--edit-clip {
  background: url(/public/assets/images/icons/detail-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--pin {
  background: url(/public/assets/images/icons/pin.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--note {
  background: url(/public/assets/images/icons/note.svg) center center no-repeat;
  background-size: contain;
  width: 42px;
  height: 46px;
}

.icon--checked {
  background: url(/public/assets/images/icons/checked.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--derivar {
  background: url(/public/assets/images/icons/derivar.svg) center center no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.icon--derivar-small {
  background: url(/public/assets/images/icons/derivar.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--document {
  background: url(/public/assets/images/icons/document.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--image {
  background: url(/public/assets/images/icons/image.png) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #B1B1B1;
  display: inline-block;
  margin: 0 8px;
}

.icon--edit {
  background: url(/public/assets/images/icons/edit.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--evaluaciones {
  background: url(/public/assets/images/icons/evaluaciones.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--user {
  background: url(/public/assets/images/icons/user.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--user-black {
  background: url(/public/assets/images/icons/user-black.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--whatsapp {
  background: url(/public/assets/images/icons/whatsapp.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--user-single {
  background: url(/public/assets/images/icons/user-single.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--change {
  background: url(/public/assets/images/icons/change.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--delete {
  background: url(/public/assets/images/icons/delete.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--remove {
  background: url(/public/assets/images/icons/remove.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--close {
  background: url(/public/assets/images/icons/close.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--check-assistance {
  background: url(/public/assets/images/icons/check-assitance.svg) center center no-repeat;
  background-size: contain;
  width: 54px;
  height: 54px;
}

.icon--check-done {
  background: url(/public/assets/images/icons/check-done.svg) center center no-repeat;
  background-size: contain;
  width: 54px;
  height: 54px;
}

.icon--colectivas {
  background: url(/public/assets/images/icons/colectivas.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--equipo {
  background: url(/public/assets/images/icons/equipo.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--mujeres {
  background: url(/public/assets/images/icons/mujeres.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--donativos {
  background: url(/public/assets/images/icons/donativos.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--coin {
  background: url(/public/assets/images/icons/coin.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--arrow-right {
  background: url(/public/assets/images/icons/arrow-right.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--arrow-right-large {
  background: url(/public/assets/images/icons/arrow-right-large.svg) center center no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
}

.icon--disabled {
  background: url(/public/assets/images/icons/disabled.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--delete-black {
  background: url(/public/assets/images/icons/delete-black.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--tag {
  background: url(/public/assets/images/icons/tag.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--historial {
  background: url(/public/assets/images/icons/historial.svg) center center no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
}

.icon--calendar-color {
  background: url(/public/assets/images/icons/calendar-color.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

/* icon text */
.icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.icon-text i {
  margin-right: 8px;
}

/* colors */
.icon--success {
  color: #46BC7C;
}

.icon-user-pic {
  width: 39px;
  height: 39px;
  margin: 0;
}
.icon-user-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     ALERTS
------------------------------------------------------------ */
.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
}
.alert.alert-danger {
  background: transparent;
  border: 0;
  color: #DB443A;
  padding: 0;
}
.alert.alert-danger.alert--small {
  padding: 0;
  margin-top: 4px !important;
}
.alert.alert--complete {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.alert.alert--complete > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert.alert-success {
  background: #A9E5B7;
  border-color: #2F6858;
  color: #2F6858;
}
.alert.alert-success a {
  color: #2F6858;
  font-weight: 700;
}
.alert.alert--small {
  padding: 5px 10px;
  font-size: 14px;
}
.alert.alert--soft-success {
  background: #F0FFF8;
  border-color: #6BBBAE;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.alert.alert--soft-success .icon {
  position: relative;
  top: 4px;
}
.alert.alert--simple-check {
  color: #46BC7C;
  padding-left: 0;
  padding-right: 0;
}
.alert.alert--simple-check .icon {
  font-size: 40px;
  margin-right: 16px;
}
.alert article:last-child {
  padding-left: 16px;
}
.alert p {
  margin-bottom: 0;
}
.alert a {
  font-weight: 700;
}

/* header alert */
.header-alert {
  border: 2px solid #AC0B1F;
  color: #AC0B1F;
  background: #fff;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 16px;
  min-width: 300px;
}
.header-alert p {
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.header-alert .icon {
  margin-right: 16px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     ICON
------------------------------------------------------------ */
.modal--md .modal-lg {
  max-width: 570px;
}

.image-400 {
  margin: 0;
  width: 100%;
  max-width: 400px;
}
.image-400 img {
  width: 100%;
}

.image-490 {
  margin: 0;
  width: 100%;
  max-width: 490px;
}
.image-490 img {
  width: 100%;
}

.image-280 {
  margin: 0;
  width: 100%;
  max-width: 280px;
}
.image-280 img {
  width: 100%;
}

.image-179 {
  margin: 0;
  width: 100%;
  max-width: 179px;
}
.image-179 img {
  width: 100%;
}

.image-39 {
  margin: 0;
  width: 39px;
  height: 39px;
}
.image-39 img {
  width: 100%;
}

.image-60 {
  margin: 0;
  width: 60px;
  height: 60px;
}
.image-60 img {
  width: 100%;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     GRID ICONS
------------------------------------------------------------ */
.illus-row {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.item-illus {
  max-width: 300px;
  width: 100%;
}
.item-illus article h2 {
  margin-bottom: 24px;
}
.item-illus figure {
  width: 100%;
  margin-bottom: 16px;
  height: 254px;
}
.item-illus figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.illus-row__col {
  margin-bottom: 40px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 680px) {
  .item-illus {
    max-width: 100%;
  }
  .item-illus figure {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .illus-row__col {
    margin-bottom: 24px;
  }
}
/* ------------------------------------------------------------
    BANNER DONATE
------------------------------------------------------------ */
.banner-donate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #C8A4E9;
  border-radius: 8px;
  padding: 32px 10%;
}

.banner-donate__article {
  padding: 0 30px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 680px) {
  .banner-donate {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 32px 0;
  }
  .banner-donate__image {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin: 0 auto;
  }
  .banner-donate__article {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
/* ------------------------------------------------------------
     STEPPER
------------------------------------------------------------ */
.stepper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.stepper p {
  margin: 0;
  padding-left: 8px;
}

.stepper__main {
  width: 100%;
  background: #CDCDCD;
  border-radius: 8px;
  position: relative;
  height: 14px;
}

.stepper__item {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  left: 0;
  top: 0;
  background: #B56CCF;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CAUSAL CARD
------------------------------------------------------------ */
.causal-card {
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #D295BF;
}
.causal-card.card--color1 {
  border-color: #C8A4E9;
}
.causal-card.card--color1 h3 {
  color: #54326F;
}
.causal-card.card--color2 {
  border-color: #C8A4E9;
}
.causal-card.card--color2 h3 {
  color: #792F78;
}
.causal-card h3 {
  color: #D295BF;
}

.card-accordeon {
  border: 0;
}
.card-accordeon .accordion-item {
  border: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button {
  font-size: 24px;
  line-height: 35px;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  background: transparent;
  border: 0;
  color: #792F78;
  -webkit-box-shadow: initial;
          box-shadow: initial;
  padding-left: 0;
  padding-right: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  border: 0;
  height: auto;
  width: auto;
  background: transparent;
}
.card-accordeon .accordion-item .accordion-header .accordion-button[aria-expanded=true]:after {
  color: #2f6858;
}
.card-accordeon .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordeon-text {
  margin-bottom: 5px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     MODAL
------------------------------------------------------------ */
.modal {
  z-index: 99999;
}
.modal.modal--gray .modal-content {
  background: #F6F6F6;
}
.modal.modal--gray .modal-header .btn-close {
  background: url(/public/assets/images/icons/close-purple.svg) center center no-repeat;
  background-size: 21px;
  opacity: 1;
  top: 50px;
}

.modal-backdrop {
  z-index: 9999;
}

.modal-header {
  border: 0;
}
.modal-header p {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
}
.modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 30px;
}

.modal-content {
  border-radius: 16px;
  padding: 20px;
}

.modal-footer {
  border: 0;
}

.modal-title.col-12 {
  width: 100%;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .modal-card__col {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 0;
  }
  .modal-selection__left {
    margin-bottom: 24px;
  }
  .modal.modal--gray .modal-header .btn-close {
    top: 25px;
  }
}
/* ------------------------------------------------------------
     CARD
------------------------------------------------------------ */
.card {
  border-color: #D295BF;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.card-title {
  margin-bottom: 24px;
}

/* item card */
.talleres-col .item-workshop__date {
  font-weight: 700;
  color: #54326F;
}

.item-workshop {
  border: 2px solid #F8F2FF;
  border-radius: 10px;
  overflow: hidden;
}
.item-workshop.active {
  border-color: #C8A4E9;
}
.item-workshop.active article {
  background: #C8A4E9;
}
.item-workshop.active article .text-gray600 {
  color: #484848;
}
.item-workshop article {
  padding: 16px 20px;
  background: #FFEFFF;
}
.item-workshop .btn {
  width: 100%;
}
.item-workshop .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #F4E0FB;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 17px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 12px;
  border: 1px solid #C8A4E9;
}
.item-workshop .form-check .form-check-input {
  margin-top: 0;
  margin-right: 8px;
  border: 1px dashed #54326F;
  background: #F4E0FB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.item-workshop .form-check .form-check-input:after {
  content: "+";
  font-size: 14px;
  opacity: 1;
  color: #54326F;
}
.item-workshop .form-check .form-check-input:checked {
  border-color: #F4E0FB;
}
.item-workshop .form-check .form-check-input:checked:after {
  content: "\f00c";
  font-size: 17px;
}
.item-workshop .form-check .form-check-label {
  font-size: 20px;
  font-weight: 700;
  color: #54326F;
}

.item-workshop__image {
  position: relative;
  margin: 0;
  height: 154px;
}
.item-workshop__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.item-workshop__image .badge {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

/* stock list */
.stock-list {
  list-style: none;
  padding: 8px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #792F78;
  margin: 0;
}
.stock-list li {
  margin: 0 2px;
}
.stock-list li .icon {
  color: #CDCDCD;
  font-size: 16px;
}
.stock-list li .icon.stock--enabled {
  color: #792F78;
}

.item-workshop__col {
  margin-bottom: 16px;
}
.item-workshop__col.reagendados .item-workshop {
  border-color: #FFEFD7;
}
.item-workshop__col.reagendados .item-workshop article {
  background: #FFEFD7;
}
.item-workshop__col.concluidos .item-workshop {
  border-color: #EDEDED;
}
.item-workshop__col.concluidos .item-workshop article {
  background: #EDEDED;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .card {
    padding: 10px;
  }
}
/* ------------------------------------------------------------
     TOOLTIP
------------------------------------------------------------ */
.tooltip-inner {
  background-color: #F4E0FB;
  border: 2px solid #C8A4E9;
  border-radius: 12px 12px 12px 0;
  color: #1f1f1f;
  padding: 20px;
  font-weight: 500;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  max-width: 300px;
  width: inherit;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #C8A4E9;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #C8A4E9;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #C8A4E9;
}

/* options */
.tooltip-options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tooltip-options li {
  width: 100%;
  margin-bottom: 8px;
}
.tooltip-options li:last-child {
  margin-bottom: 0;
}
.tooltip-options li a,
.tooltip-options li button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
}
.tooltip-options li a .icon,
.tooltip-options li button .icon {
  margin-right: 8px;
}

.tooltip--white.bs-tooltip-start .tooltip-arrow::before, .tooltip--white.bs-tooltip-bottom .tooltip-arrow::before {
  border-left-color: #fff;
  border-bottom-color: #fff;
}
.tooltip--white .tooltip-inner {
  background: #fff;
  border: 0;
  padding: 16px 30px;
  border-radius: 12px 0 12px 12px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -2px 7px 18px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -2px 7px 18px 0px rgba(0, 0, 0, 0.25);
}
.tooltip--white .tooltip-inner .tooltip-options li {
  margin-bottom: 16px;
}
.tooltip--white .tooltip-inner .tooltip-options li:last-child {
  margin-bottom: 0;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     DROPZONE
------------------------------------------------------------ */
.dropzone {
  padding: 16px;
  border: 1px dashed #845DB6;
  border-radius: 8px;
  text-align: center;
}

.dropzone__files {
  margin-top: 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.dropzone__files ul {
  list-style: none;
  padding: 16px 0 0 0;
  margin: 0;
  width: 100%;
}
.dropzone__files ul li {
  margin-bottom: 0;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     VIOLENTOMETRO
------------------------------------------------------------ */
.violent-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 19px;
  height: 52px;
}
.violent-row:last-child {
  margin-bottom: 0;
}
.violent-row .form-check-input[type=checkbox] {
  border-color: #91D6C4;
}
.violent-row .form-check-input[type=checkbox]:checked {
  color: #2F6858;
}
.violent-row .form-check-input[type=checkbox]:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.violent-row .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 440px;
}
.violent-row .form-check.check--center label {
  padding-top: 6px;
}
.violent-row .form-check label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 52px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-row .form-check label * {
  width: 100%;
}
.violent-row .form-check label small {
  display: block;
  font-size: 12px;
  letter-spacing: -0.3px;
}

.violent-number {
  color: #1f1f1f;
  margin-right: 40px;
  padding-right: 30px;
  text-align: right;
  position: relative;
  margin-bottom: 0;
  width: 50px;
  height: 24px;
  top: 5px;
}
.violent-number:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #1f1f1f;
  display: block;
  right: -14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.violent-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.violent-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 44px;
  margin-right: 16px;
  margin-left: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0;
  position: relative;
}
.violent-figure:after {
  content: "";
  background: #EDEDED;
  width: 28px;
  border-radius: 30px;
  height: 100%;
  position: absolute;
  bottom: 12px;
  left: 50%;
  z-index: 1;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.violent-figure__end {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #7500BD;
  border: 10px solid #EDEDED;
  position: relative;
  top: -12px;
  z-index: 2;
}

.violent-level:first-child .violent-figure__item:first-child {
  border-radius: 30px 30px 0 0;
  height: 100px;
}
.violent-level.level1 p {
  top: 100%;
}

.violent-figure__item {
  width: 14px;
  height: 70px;
  position: relative;
  z-index: 3;
}
.violent-figure__item.item--color4 {
  background: #7500BD;
}
.violent-figure__item.item--color3 {
  background: #DC0303;
}
.violent-figure__item.item--color2 {
  background: #FFAB48;
}
.violent-figure__item.item--color1 {
  background: #FFE248;
}

.violent-level {
  width: 14px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-level p {
  position: absolute;
  top: 50%;
  left: -30px;
  -webkit-transform: translateX(-50%) rotate(270deg);
          transform: translateX(-50%) rotate(270deg);
  margin: 0;
  white-space: nowrap;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .violent-number {
    display: none;
  }
  .violent-row .form-check-label {
    font-size: 14px;
    line-height: 14px;
  }
  .violent-row .form-check-label small {
    font-size: 11px;
    line-height: 18px;
  }
  .violent-row .form-check {
    width: 290px;
  }
}
/* ------------------------------------------------------------
     STATUS BAR
------------------------------------------------------------ */
.status-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 32px 0;
}

.status-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 16px;
}
.status-item.status--success .status-item__top hr {
  background-color: #449A82;
}
.status-item.status--success .status-item__icon {
  background: #449A82;
}
.status-item.status--process .status-item__icon {
  background: #845DB6;
  border: 5px solid #fff;
  outline: 2px solid #845DB6;
}

.status-item__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.status-item__top hr {
  width: 100%;
  height: 3px;
  background-color: #8A8A8A;
  border: 0;
  opacity: 1;
  margin: 0;
  width: 90%;
}

.status-item__icon {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #8A8A8A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
}
.status-item__icon i {
  color: #fff;
}

.status-item__bottom p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

/* status bar mobile */
.status-bar__mobile {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.status-bar__circle {
  width: 70px;
  height: 70px;
  position: relative;
  margin-right: 24px;
}

.status-bar__step {
  background: #845DB6;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  border-radius: 50%;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .status-bar {
    display: none;
  }
  .status-bar__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px 0;
  }
}
@media (max-width: 680px) {
  .status-bar__step {
    font-size: 16px;
  }
}
/* ------------------------------------------------------------
     CHATBOX
------------------------------------------------------------ */
.list-checker {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-checker li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}
.list-checker li:last-child {
  margin-bottom: 0;
}
.list-checker li article {
  padding-left: 16px;
}
.list-checker li article p {
  margin: 0;
}

.chatbox-info {
  background: #F6F6F6;
  padding: 40px 32px;
  border-radius: 8px;
  border: 1px solid #D295BF;
}

.chatbox-info__row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chatbox-action {
  background: #fff;
  padding: 32px;
  border-radius: 16px;
}

.chatbox-action__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.chatbox-action__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.chatbox-action__user:last-child {
  margin-bottom: 0;
}
.chatbox-action__user figure {
  margin: 0;
  width: 77px;
  height: 77px;
}
.chatbox-action__user figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.chatbox-action__user article {
  padding-left: 16px;
}
.chatbox-action__user article p,
.chatbox-action__user article h4,
.chatbox-action__user article h3,
.chatbox-action__user article h2 {
  margin: 0;
}

.chatbox__send {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.chatbox__send .rce-container-input {
  border-radius: 0 0 20px 20px;
}

/* placeholder */
.chatbox-placeholder {
  width: 100%;
  background: #FFEFFF url(/public/assets/images/background/pattern.png) center center repeat;
  background-size: 300px;
  height: 700px;
  border-radius: 10px;
  overflow: hidden;
}

.chat-container .rce-container-mlist {
  height: 654px;
}
.chat-container .rce-mlist {
  overflow-y: scroll;
}
.chat-container .rce-mbox {
  min-width: 320px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .chatbox-action__user {
    margin-bottom: 16px;
  }
}
@media (max-width: 992px) {
  .chatbox-info__text {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .chatbox-info__module {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .chatbox-action__top {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .chatbox-info {
    padding: 40px 22px;
  }
}
/* ------------------------------------------------------------
     BADGES
------------------------------------------------------------ */
.badge {
  border-radius: 8px;
  margin: 0;
}
.badge.badge-label {
  padding: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.badge.badge-label .icon {
  margin-right: 6px;
}
.badge.badge-label.badge--danger {
  border: 1px solid #AC0B1F;
}
.badge.badge--success {
  background: #E0FBE1;
  border: 1px solid #529770;
  color: #E0FBE1;
}
.badge.badge--success * {
  color: #529770;
}
.badge.badge--primary {
  background: #E0FBFB;
  border: 1px solid #1C358E;
  color: #1C358E;
}
.badge.badge--primary * {
  color: #1C358E;
}
.badge.badge--info {
  background: #F4E0FB;
  border: 1px solid #711C8E;
  color: #711C8E;
}
.badge.badge--info * {
  color: #711C8E;
}
.badge.badge--blue {
  background: #BFFFFF;
  border: 1px solid #0B72AC;
  color: #0B72AC;
}
.badge.badge--blue * {
  color: #0B72AC;
}
.badge.badge--empty {
  height: 72px;
  width: 100%;
}
.badge.badge--status {
  border-radius: 8px;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
}
.badge.badge--status-acompanada {
  background: #E9D8FE;
  color: #792F78;
}
.badge.badge--status-registrada {
  background: #FFAB48;
  color: #fff;
}
.badge.badge--status-success {
  color: #fff;
  background: #46BC7C;
}
.badge.badge--status-taller {
  color: #792F78;
  background: #E9D8FE;
}
.badge.badge--status-done {
  color: #3366FF;
  background: #D8ECFE;
}
.badge.badge--status-undone {
  color: #792F78;
  background: #D295BF;
}
.badge.badge--status-info {
  color: #8A8A8A;
  background: #F6F6F6;
}
.badge.badge--ola {
  color: #546D69;
  background: #A6ECD3;
}
.badge.badge--ola {
  color: #546D69;
  background: #A6ECD3;
}
.badge.badge--medica {
  color: #161639;
  background: #A6D7EC;
}
.badge.badge--colectiva {
  color: #A24B8F;
  background: #FEE2F8;
}
.badge.badge--icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.badge.badge--icon span {
  margin-right: 8px;
  font-size: 18px;
}
.badge.badge--warning {
  color: #7F6B59;
  background: #FFF1BF;
}
.badge.badge--danger {
  color: #AC0B1F;
  background: #FFDED7;
}
.badge.badge--psicologica {
  color: #0B72AC;
  background: #DEFDFD;
}
.badge.badge--complejidad {
  color: #6A588F;
  background: #E0DFFB;
}
.badge.badge--discapacidad {
  color: #A24B8F;
  background: #F8DEF7;
}
.badge.badge--no-spanish {
  color: #2f6858;
  background: #E9F3D4;
}
.badge.badge--active {
  color: #2f6858;
  background: #E0FBE1;
}
.badge.badge--inactive {
  color: #AC0B1F;
  background: #FFDED7;
}
.badge.badge--success-alert {
  background: #fff;
  border: 2px solid #449A82;
  color: #449A82;
  border-radius: 20px;
  display: inline-block;
  white-space: normal;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     RATING
------------------------------------------------------------ */
.rate-row textarea {
  height: 230px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .rate-row textarea {
    height: 130px;
  }
  .rate-row .card {
    padding: 24px;
  }
  .rate-col__left {
    margin-bottom: 24px;
  }
}
/* ------------------------------------------------------------
     ACCORDION
------------------------------------------------------------ */
.accordion.accordion-regular .accordion-item .accordion-button {
  font-size: 21px;
}
.accordion .accordion-item {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
}
.accordion .accordion-item .accordion-button {
  font-size: 18px;
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0;
  color: #484848;
  border-radius: 0 !important;
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: transparent;
}
.accordion .accordion-item .accordion-button:focus {
  border: 0;
  -webkit-box-shadow: initial;
          box-shadow: initial;
}
.accordion .accordion-item .accordion-button:after {
  background-image: url(../public/assets/images/icons/arrow-down.svg);
  background-size: 18px;
}
.accordion .accordion-item .accordion-collapse.show {
  border-top: 1px solid var(--bs-accordion-border-color);
}

.accordion-inner .accordion-item {
  border: 0;
}
.accordion-inner .accordion-item .accordion-header .accordion-button {
  background: #F6F6F6;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: initial;
          box-shadow: initial;
  color: #484848;
}
.accordion-inner .accordion-item .accordion-collapse.show {
  border: 0;
}

/* user pic selection */
.user-pic-selection {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.user-pic-selection li {
  margin-right: 8px;
  margin-bottom: 8px;
}
.user-pic-selection li button {
  background: transparent;
  border: 0;
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.user-pic-selection li button:hover {
  opacity: 0.5;
}
.user-pic-selection li button figure {
  width: 60px;
  height: 60px;
}
.user-pic-selection li button figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.accordion-table {
  width: 100%;
}
.accordion-table tr {
  background: #F6F6F6;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 6px;
}
.accordion-table tr td {
  padding: 8px 16px;
}

.accordion-reset-padding.accordion-header button {
  padding-left: 0 !important;
  padding-right: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .accordion-item-inner .text-20 {
    font-size: 16px !important;
    padding: 0;
  }
  .accordion-table tr {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .accordion-table tr td {
    width: 100%;
  }
  .accordion-table tr td:empty {
    display: none;
  }
  .accordion-table tr .text-right {
    text-align: left;
  }
}
/* ------------------------------------------------------------
     CALENDAR
------------------------------------------------------------ */
.react-calendar {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
  background: #fff;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.react-calendar .react-calendar__navigation {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 16px;
}
.react-calendar .react-calendar__navigation .react-calendar__navigation__label .react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  font-weight: 400;
  color: #484848;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
  text-align: center;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile {
  color: #484848;
  font-weight: 400;
  padding: 5px;
  font-size: 14px;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  color: #B1B1B1;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active {
  background: #845DB6;
  color: #fff;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:hover {
  background: #FFEFFF;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:hover.react-calendar__tile--active {
  background: #B56CCF;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     DATATABLE
------------------------------------------------------------ */
.data-table.table--result [role=cell]:first-child {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
  padding-left: 32px !important;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background: #792F78;
  border-radius: 8px 8px 0 0;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol > div {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background: #F6F3F0;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px 0;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell a {
  text-decoration: none;
}
.data-table .rdt_Pagination {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.data-table .rdt_Pagination button:hover {
  background: #FFEFFF;
}
.data-table .form-check {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.data-table .form-check input {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-left: 0;
}
.data-table .form-select {
  width: 70px;
  display: inline-block;
}

.data-table__time > span {
  display: block;
  width: 100%;
  text-align: center;
}
.data-table__time .MuiStack-root .MuiFormControl-root {
  min-width: 100px;
  padding: 10px;
}
.data-table__time .MuiStack-root .MuiFormControl-root input {
  padding: 8.5px 0 8px 10px;
  font-size: 13px;
}

.data-table__number {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #8A8A8A;
  border-radius: 2px;
  padding: 10px;
  min-width: 45px;
  width: auto;
}
.data-table__number.number--wait {
  background: #CDCDCD;
  text-transform: uppercase;
}
.data-table__number.number--fail {
  background: #FFBDA8;
  color: #9D1D2A;
  border-color: #9D1D2A;
  text-transform: uppercase;
}
.data-table__number.disabled {
  opacity: 0.7;
}

.data-table__input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.data-table__input p {
  margin: 0 4px;
}
.data-table__input input {
  width: 50px;
}

/* mobile */
.data-table-mobile__header {
  width: 100%;
  background: #792F78;
  color: #fff;
  padding: 12px 30px;
  text-align: center;
}
.data-table-mobile__header p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.data-table-mobile__accordion table {
  width: 100%;
  font-size: 14px;
}
.data-table-mobile__accordion table td {
  padding: 24px 10px;
}
.data-table-mobile__accordion .accordion-item .accordion-header button {
  text-transform: uppercase;
  color: #845DB6;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  display: block;
  text-align: center;
}
.data-table-mobile__accordion .accordion-item .accordion-header button:after {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.data-table-mobile__accordion .accordion-item .accordion-header button[aria-expanded=true]:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.user-table-detail-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.user-table-detail-image figure {
  margin-right: 32px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     TAG MANAGER
------------------------------------------------------------ */
.ReactTags__tags .ReactTags__tagInput {
  position: relative;
  width: 100%;
}
.ReactTags__tags .ReactTags__tagInput .ReactTags__tagInputField {
  background-color: #F6F6F6;
  background-size: 18px;
  border: 0;
  height: 40px;
  font-size: 13px;
  width: 100%;
  border-radius: 12px;
  padding: 0.375rem 0.75rem;
  /* firefox 19+ */
  /* ie */
}
.ReactTags__tags .ReactTags__tagInput .ReactTags__tagInputField::-webkit-input-placeholder {
  color: #484848 !important;
}
.ReactTags__tags .ReactTags__tagInput .ReactTags__tagInputField::-moz-placeholder {
  color: #484848 !important;
}
.ReactTags__tags .ReactTags__tagInput .ReactTags__tagInputField:-ms-input-placeholder {
  color: #484848 !important;
}
.ReactTags__tags .ReactTags__tagInput .ReactTags__tagInputField:-moz-placeholder {
  color: #484848 !important;
}
.ReactTags__tags .ReactTags__selected {
  margin-bottom: 8px;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag {
  border-radius: 90px;
  padding: 6px 10px;
  background: #FADEFF;
  border: 1px solid #A24B8F;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #A24B8F;
  font-weight: 700;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag .ReactTags__remove {
  font-size: 18px;
}
.ReactTags__tags .ReactTags__suggestions {
  position: absolute;
  background: #F6F6F6;
  border-radius: 12px;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
}
.ReactTags__tags .ReactTags__suggestions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ReactTags__tags .ReactTags__suggestions ul li {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 12px;
  cursor: pointer;
}
.ReactTags__tags .ReactTags__suggestions ul li span mark {
  background: #FADEFF;
  border-radius: 12px;
}
.ReactTags__tags .ReactTags__suggestions .ReactTags__activeSuggestion {
  background: #F8F2FF;
  color: #A24B8F;
}
.ReactTags__tags .ReactTags__suggestions .ReactTags__suggestion {
  padding: 12px 10px;
  font-size: 13px;
  color: #484848;
}
.ReactTags__tags .ReactTags__suggestions .ReactTags__suggestion:hover {
  background: #FADEFF;
  color: #A24B8F;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     NOTIFICATION MESSAGE
------------------------------------------------------------ */
.notification-message {
  padding: 16px;
  background: #EDEDED;
  border: 1px solid #B1B1B1;
  border-radius: 8px;
}
.notification-message.message--warning {
  background: #FFF1BF;
  border-color: #7F6B59;
}
.notification-message.message--warning p {
  color: #7F6B59;
}
.notification-message.message--danger {
  background: #FFDED7;
  border-color: #AC0B1F;
}
.notification-message.message--danger p {
  color: #AC0B1F;
}
.notification-message.message--info {
  background: #DEFDFD;
  border-color: #0B72AC;
}
.notification-message.message--info p {
  color: #0B72AC;
}
.notification-message.message--neutral {
  background: #E0DFFB;
  border-color: #6A588F;
}
.notification-message.message--neutral p {
  color: #6A588F;
}
.notification-message.message--success {
  background: #E9F3D4;
  border-color: #546D69;
}
.notification-message.message--success p {
  color: #546D69;
}
.notification-message p:last-child {
  margin-bottom: 0;
}
.notification-message p i {
  margin-right: 8px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     NOTES
------------------------------------------------------------ */
.note-wrapp {
  width: 100%;
}

.note-row {
  width: 100%;
  margin-bottom: 28px;
}

.note-row__body {
  background: #FCEEF2;
  border: 1px solid #D295BF;
  border-radius: 8px;
  padding: 12px;
}
.note-row__body p {
  margin: 0;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     PROCESS CARD
------------------------------------------------------------ */
.card-process-info {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0;
}

.card-process__row {
  padding: 10px;
  width: 100%;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card-process__row p {
  margin-bottom: 0;
}
.card-process__row > div {
  width: 50%;
}

.card-process__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: right;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .card-process__row > div {
    width: 100%;
  }
  .card-process__right {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: left;
  }
  .card-process__right .btn {
    margin-top: 16px;
    width: 100%;
  }
  .card-process-info {
    padding: 16px 0;
  }
}
/* ------------------------------------------------------------
     USER CARD
------------------------------------------------------------ */
.user-card {
  background: #fff;
  border: 1px solid #D295BF;
  border-radius: 16px;
  padding: 32px;
  position: relative;
  height: 100%;
}
.user-card.user--hover {
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.user-card.user--hover:hover {
  background: #FFEFFF;
}
.user-card .chatbox-action__user {
  margin-bottom: 24px;
}
.user-card .chatbox-action__user:last-child {
  margin-bottom: 0;
}

.user-card__detail {
  background: #FBF8FF;
  padding: 16px;
}

.user-card__url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     PAGINATOR
------------------------------------------------------------ */
.data-paginator {
  width: 100%;
  padding-top: 36px;
}
.data-paginator div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.data-paginator .btn {
  font-size: 12px;
  font-weight: 700;
  color: #545454;
  padding: 12px;
  width: 24px;
  height: 24px;
  min-height: initial;
  border-radius: 2px;
  margin: 0 6px;
}
.data-paginator .btn:hover {
  opacity: 0.7;
}
.data-paginator .btn.active {
  border-color: #D295BF;
  color: #D295BF;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     QUALITY
------------------------------------------------------------ */
.quality-score__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.quality-score__number i {
  margin-right: 8px;
  font-size: 18px;
}

.quality-score-star ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quality-score-star ul li {
  margin-right: 8px;
}
.quality-score-star ul li:last-child {
  margin-right: 0;
}
.quality-score-star ul li i {
  color: #845DB6;
  font-size: 18px;
}

.quality-score-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quality-score-list > div {
  margin-right: 22px;
}
.quality-score-list > div:last-child {
  margin-right: 0;
}

.quality-score-info {
  width: 100%;
  margin-bottom: 20px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .quality-score-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .quality-score-list > div {
    width: 100%;
    margin-bottom: 16px;
  }
  .quality-score-list > div:last-child {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------
     CHART
------------------------------------------------------------ */
.chart-element {
  width: 368px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .chart-element {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .chart-element {
    width: 300px;
    margin: 0 auto;
  }
}
/* ------------------------------------------------------------
     DONATIONS TABS
------------------------------------------------------------ */
.data-info-tab {
  width: 100%;
}
.data-info-tab ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.data-info-tab ul li button {
  padding: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #845DB6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-weight: 400;
}
.data-info-tab ul li button:after {
  content: "";
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 10px;
  display: block;
}
.data-info-tab ul li button.active {
  color: #54326F;
  font-weight: 500;
}
.data-info-tab ul li button.active:after {
  background: #54326F;
}
.data-info-tab ul li button i {
  margin-left: 8px;
}

.data-info__date-item {
  margin-bottom: 24px;
  width: 100%;
}
.data-info__date-item:last-child {
  margin-bottom: 0;
}

.data-donate-wrapp {
  width: 100%;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     LOADER
------------------------------------------------------------ */
.loader-wrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #FFEFFF;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #C8A4E9;
  border-bottom-color: #845DB6;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     AUTH
------------------------------------------------------------ */
.auto-row__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/* active code */
.active-choose {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.active-choose button {
  margin-right: 30px;
}
.active-choose button:last-child {
  margin-right: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .auto-row__right {
    display: none;
  }
  .active-choose {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
/* ------------------------------------------------------------
     DONATE
------------------------------------------------------------ */
.box-donate-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 32px;
}

.box-donate {
  margin: 0 16px;
}
.box-donate button,
.box-donate a {
  background: #fff;
  border: 1px solid #D295BF;
  border-radius: 16px;
  padding: 32px;
  color: #1f1f1f;
  width: 277px;
  height: 233px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  text-decoration: none;
}
.box-donate button:hover, .box-donate button.active,
.box-donate a:hover,
.box-donate a.active {
  border-color: #54326F;
  background: #F4E0FB;
}
.box-donate button:hover p, .box-donate button.active p,
.box-donate a:hover p,
.box-donate a.active p {
  color: #54326F;
}

.box-donate__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.box-donate__icon .icon {
  margin: 0 8px;
}

/* donate banner */
.donate-banner {
  background: #E9D8FE;
  border-radius: 10px;
  padding: 24px;
  border: 1px solid #C8A4E9;
}
.donate-banner .row {
  margin: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-card table p {
  margin-bottom: 0;
}
.dashboard-card .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-card__col {
  border-right: 1px solid #CDCDCD;
}
.dashboard-card__col:last-child, .dashboard-card__col:last-of-type {
  border-right: 0;
}
.dashboard-card__col:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .box-donate {
    margin-bottom: 32px;
  }
  .dashboard-card__col:nth-child(2) {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .box-donate {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }
  .box-donate button,
  .box-donate a {
    width: 100%;
    height: 120px;
    padding: 10px 32px;
  }
  .box-donate-grid {
    margin-bottom: 0;
  }
  .donate-banner {
    padding: 16px;
  }
}
/* ------------------------------------------------------------
     DASHBOARD
------------------------------------------------------------ */
.dashboard-aside__inner {
  position: sticky;
  top: 0;
}

.dashboard-info__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 32px;
}

/* top title */
.top-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* user tab col */
.user-info-tab__subtitle-right {
  text-align: right;
}

.user-info-tab__col {
  margin-bottom: 24px;
}

/* view body */
.view-body__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* dashboard admin */
.dashboard-index {
  background: #F6F6F6;
}

.dashboard-index__container {
  padding-bottom: 36px;
  padding-top: 36px;
}

.dashboard-card {
  padding: 32px 22px;
  border-radius: 8px;
}
.dashboard-card.dashboard--1 {
  background: #C8A4E9;
}
.dashboard-card.dashboard--2 {
  background: #fff;
  border: 1px solid #8A8A8A;
}
.dashboard-card.dashboard--3 {
  background: #E9D8FE;
  border: 1px solid #C8A4E9;
}
.dashboard-card.dashboard--4 {
  background: #fff;
  border: 1px solid #C8A4E9;
}
.dashboard-card.dashboard--v100 {
  height: 100%;
}
.dashboard-card.dashboard--5 {
  background: #F6F6F6;
  border: 1px solid #C8A4E9;
}

.dashboard-card__inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.dashboard-card__inner-content {
  width: 100%;
}

.dashboard-card__buttons {
  width: 100%;
  padding-top: 48px;
}
.dashboard-card__buttons ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dashboard-card__buttons ul li {
  margin-bottom: 8px;
  width: 100%;
}
.dashboard-card__buttons ul li:last-child {
  margin-bottom: 0;
}
.dashboard-card__buttons ul li .btn {
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

/* logo */
.logo-init {
  width: 146px;
  margin: 0;
}
.logo-init img {
  width: 100%;
}

/* number */
.data-number-currency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.data-number-currency__init {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 8px;
}
.data-number-currency__init small {
  padding-right: 8px;
}

.donations-list__item {
  width: 100%;
}
.donations-list__item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.donations-list__item ul li {
  width: 100%;
  margin-bottom: 12px;
}

/* talleres */
.talleres-list {
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.talleres-list ul {
  list-style: none;
  padding: 0 20px;
  margin: 0;
}
.talleres-list ul li {
  width: 100%;
  margin-bottom: 12px;
}

/* colectiva */
.colectiva-list-data {
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.colectiva-list-data ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.colectiva-list-data ul li {
  width: 100%;
  margin-bottom: 12px;
}

.dashboard-index__middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .dashboard-index__desktop {
    display: none;
  }
  .dashboard-index__middle {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 992px) {
  .user-info-tab__subtitle-right {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .title-section {
    display: none;
  }
  .dashboard-aside {
    margin-bottom: 24px;
  }
  .dashboard-info__bottom {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .top-title {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .view-head-row {
    margin-bottom: 32px;
  }
  .dashboard-card .btn {
    width: 100%;
  }
  .data-number-currency .text-24 {
    font-size: 16px;
    line-height: 18px;
  }
}
/* ------------------------------------------------------------
     USER DETAIL
------------------------------------------------------------ */
.view-head {
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #CDCDCD;
  background: #F6F6F6;
}

.view-detail__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.view-detail__tags > div {
  margin-right: 8px;
  margin-bottom: 8px;
}

.view-detail__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.view-detail__action.no-flex {
  display: block;
}
.view-detail__action .btn {
  margin-bottom: 16px;
  max-width: 270px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.view-detail__action-col {
  text-align: right;
}
.view-detail__action-col .btn {
  margin-bottom: 16px;
}

.view-body__aside .btn {
  position: sticky;
  top: 24px;
}

.view-body__info {
  padding-right: 30px;
}

.table-user-data {
  width: 100%;
}
.table-user-data td:last-child {
  text-align: right;
  color: #484848;
  font-weight: 500;
}

.data-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #8A8A8A;
  font-weight: 500;
}
.data-info i {
  margin-right: 16px;
}

/* image user */
.image-user {
  width: 100%;
  max-width: 348px;
  border-radius: 18px;
  border: 1px solid #D295BF;
}
.image-user figure {
  width: 100%;
  height: 222px;
  padding: 19px;
}
.image-user figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  overflow: hidden;
  border-radius: 14px;
}

.image-user__data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 19px;
  border-top: 1px solid #D295BF;
}
.image-user__data article {
  width: 90%;
}
.image-user__data article p {
  margin-bottom: 0;
  padding-left: 8px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .view-head {
    background: transparent;
    border: 0;
    padding: 0;
  }
  .view-detail__action button {
    width: 100%;
  }
  .view-detail h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .data-info p {
    margin: 0;
  }
  .view-body__info {
    padding: 0;
  }
}
/* ------------------------------------------------------------
     TALLERES
------------------------------------------------------------ */
.create-taller-item .item-workshop__col {
  max-width: 275px;
  width: 100%;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
    LARGE
------------------------------------------------------------ */
/* ------------------------------------------------------------
    MEDIUM
------------------------------------------------------------ */
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 45px;
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: 35px;
  }
  h3,
  .h3 {
    font-size: 21px;
    line-height: 35px;
  }
  .module {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .module-bottom {
    padding-bottom: 40px;
  }
  .module-top {
    padding-top: 40px;
  }
  .module40 {
    padding: 30px 0;
  }
  .module-bottom40 {
    padding-bottom: 30px;
  }
  .module-top40 {
    padding-top: 30px;
  }
  .text-24,
  .text-24 *,
  .text-20,
  .text-20 * {
    font-size: 18px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 *,
  .text-28,
  .text-28 * {
    font-size: 24px;
    line-height: 35px;
  }
  .text-64,
  .text-64 * {
    font-size: 40px;
    line-height: 45px;
  }
  .text-52,
  .text-52 * {
    font-size: 32px;
    line-height: 40px;
  }
}
/* ------------------------------------------------------------
    SMALL
------------------------------------------------------------ */
@media (max-width: 680px) {
  .body-row {
    margin: 0;
  }
  .module {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .module-bottom {
    padding-bottom: 30px;
  }
  .module-top {
    padding-top: 30px;
  }
  .module40 {
    padding: 20px 0;
  }
  .module-bottom40 {
    padding-bottom: 20px;
  }
  .module-top40 {
    padding-top: 20px;
  }
  .text-20,
  .text-20 * {
    font-size: 16px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 *,
  .text-28,
  .text-28 * {
    font-size: 20px;
    line-height: 30px;
  }
  .text-64,
  .text-64 * {
    font-size: 32px;
    line-height: 40px;
  }
}